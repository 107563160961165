export const PASSWORDS_DO_NOT_MATCH = "Passwords do not match";
export const PASSWORD_IS_TOO_WEAK = "Password is too weak (At least one uppercase, lowercase, and number)";
export const USERNAME_IS_TAKEN = "Username is taken";
export const EMAIL_IS_USED = "Email is already in use";
export const EMPTY_FIELD = "At least one field is empty";
export const EMAIL_INVALID = "Email contains invalid characters";
export const FIRST_NAME_INVALID = "First name contains invalid characters (only letters, spaces, or a hyphen)";
export const LAST_NAME_INVALID = "Last name contains invalid characters (only letters, spaces, or a hyphen)";
export const USERNAME_INVALID = "Username contains invalid characters";
export const PASSWORD_INVALID = "Password contains invalid characters";
export const USERNAME_LOGIN_DNE = "Username or email does not exist";
export const PASSWORD_LOGIN_INVALID = "Password is invalid";